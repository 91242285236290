import React, {
    useContext, useState, useEffect
} from 'react';
import { Div } from '../../hybrid/wrappers';
import Styles from '../../common/decorators/Styles';
import getStyles from '../../project/styles/layouts-styles/single-layout-styles';
import MainFooter from './components/MainFooter';
import MainHeader from './components/MainHeader';
import Language from '../../common/decorators/Language';
import SeoData from '../../widgets/seoData/SeoData';
import AuthExternal from '../../common/decorators/AuthExternal';
import PropTypes from 'prop-types';
import uikit from '../../project/uikit/styles';
import UserContext, { UserProfileContext } from '../../common/utils/getContext';
import { delToken } from '../../common/utils/utils';


const Main = ({
    children, title, active, styles, navigation, translates
}) => {
    const {
        userToken, lang, setUserToken
    } = useContext(UserContext);

    const [timerIdFirst, setTimerIdFirst] = useState(new Date().getTime());

    const [mouseStopped, setMouseStopped] = useState(false);

    useEffect(() => {
        let timeoutId;
        const handleMouseMove = () => {
            setMouseStopped(false);
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                setTimerIdFirst(new Date().getTime())
                setMouseStopped(true);
            }, 900000); // 15 minute
        };
        window.addEventListener('mousemove', handleMouseMove);
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            clearTimeout(timeoutId);
        };
    }, []);

    useEffect(() => {
        if (mouseStopped == false && userToken && new Date().getTime() - timerIdFirst > 4000) {
            delToken(userToken, lang, setUserToken)
        }
    }, [mouseStopped, timerIdFirst]);


    return (
        <Div styles={{ background: '#040200' }}>
            <AuthExternal>
                <Language translates={translates} page={'/'} navigation={navigation}>
                    <Div styles={{ ...uikit.flexColumn, 'min-height': '100%' }}>
                        <SeoData title={title}/>
                        <MainHeader/>
                        <Div>
                            {children}
                        </Div>
                        <MainFooter/>
                    </Div>
                </Language>
            </AuthExternal>
        </Div>
    )
};
Main.propTypes = {
    title: PropTypes.string,
    styles: PropTypes.object,
    smallFooter: PropTypes.bool,
    breadcrumbs: PropTypes.array,
    translates: PropTypes.object
};
export default Styles(Main, getStyles);