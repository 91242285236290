import React, { useState } from "react";
import { motion } from 'framer-motion'
import styles from "./Questions.module.scss";
import { Div, H2, P, svgSprite } from "../../../../hybrid/wrappers";
import T from "../../../../common/components/T";


const titleVariants = {
	offscreen: {
		x: 300,
		opacity: 0
	},
	onscreen: {
		x: 0,
		opacity: 1,
		transition: {
			duration: 0.4
		}
	}
};
const variantQuestionBlock = {
	offscreen: {
		y: 100,
		opacity: 0
	},
	onscreen: {
		y: 0,
		opacity: 1,
		transition: {
			delay: 0.2,
			duration: 1
		}
	}
};
export const Questions = () => {
	const [openAnswearsList, setOpenAnsearsList] = useState([]);

	const QuestionsList = [
		{
			question: (
				<T textName="qeustionsPageItemName-1" defaultStr="Что значит бизнес-партнер?" page="/" />
			),
			answear: (
				<T
					textName="qeustionsPageItemAnswear-1"
					defaultStr="Партнером считается человек, который зарегистрировался на платформе (зарегистрироваться можно только используя реферальную ссылку или обратившись в службу поддержки) и приобрёл продукцию на сумму от 45 000 тенге. После этого для партнера начинают работать первые бонусы и реферальные программы"
					page="/"
				/>
			),
		},
		{
			question: (
				<T
					textName="qeustionsPageItemName-2"
					defaultStr="Как я могу на этом зарабатывать?"
					page="/"
				/>
			),
			answear: (
				<T
					textName="qeustionsPageItemAnswear-2"
					defaultStr="Наш маркетинг план имеет 9 видов бонусов. Вы можете зарабатывать просто рекомендуя продукт компании и получать до 20% по реферальной программе со своих продаж. Также вы можете строить команду активных менеджеров и получать бонусы со всего товарооборота, который сделала ваша команда. Подробнее обо всех выплатах вы можете узнать из Маркетинг плана, из обучения или на личной консультации у вашего менеджера"
					page="/"
				/>
			),
		},
		{
			question: (
				<T
					textName="qeustionsPageItemName-3"
					defaultStr="А сколько я буду зарабатывать?"
					page="/"
				/>
			),
			answear: (
				<T
					textName="qeustionsPageItemAnswear-3"
					defaultStr="Сумма вашего заработка будет зависеть от вашей активности, вашего товарооборота и товарооборота, который сделает ваша команда. Заработок в партнерских программах начинается с малого и растет каждый месяц по мере роста вашей команды и вашего товарооборота. В среднем новый менеджер за первый месяц зарабатывает от 400 000тг, и выходит на доход 1 000 000 тенге через 3-4 месяца активной работы"
					page="/"
				/>
			),
		},
		{
			question: (
				<T
					textName="qeustionsPageItemName-4"
					defaultStr="Как вы будете выплачивать деньги?"
					page="/"
				/>
			),
			answear: (
				<T
					textName="qeustionsPageItemAnswear-4"
					defaultStr="Все выплаты производятся официально, бонусы начисляются один раз в неделю. Вы сможете следить за суммой своих начислений в личном кабинете и при необходимости обналичивать их в удобное для вас время"
					page="/"
				/>
			),
		},
		{
			question: (
				<T textName="qeustionsPageItemName-5" defaultStr="Как зарегистрироваться?" page="/" />
			),
			answear: (
				<T
					textName="qeustionsPageItemAnswear-5"
					defaultStr="Для регистрации вы можете обратиться к менеджеру, который вас направил на этот сайт, он даст вам реферальную ссылку для регистрации, или обратиться в службу поддержки и вам помогут зарегистрироваться и направят к менеджеру, который лично будет сопровождать вас"
					page="/"
				/>
			),
		},
		{
			question: (
				<T textName="qeustionsPageItemName-6" defaultStr="Я хочу личной встречи!" page="/" />
			),
			answear: (
				<T
					textName="qeustionsPageItemAnswear-6"
					defaultStr="Если ссылку на этот сайт вам дал менеджер, пожалуйста обратитесь к нему. Если вы пришли из открытых источников, пожалуйста обратитесь в службу поддержки и мы подберем для вас удобное время и нашего лучшего менеджера, который вам все подробно объяснит и проведет личную встречу"
					page="/"
				/>
			),
		},
	];

	const onOpenAnswear = (index) => {
		if (openAnswearsList.includes(index)) {
			setOpenAnsearsList(openAnswearsList.filter((el) => el !== index));
		} else {
			setOpenAnsearsList((prev) => [...prev, index]);
		}
	};

	return (
		<Div className={styles.wrapper}>
			<Div className="main-page__container">
				<H2 className={styles.title}>
					<T textName="questionsPageTitle" defaultStr="ответы на ваши вопросы" page="/" />
				</H2>
				<Div className={styles.questionsWrapper}>
					{QuestionsList.map((el, i) => {
						let isOpen = openAnswearsList.includes(i);
						return (
							<motion.div
										initial="offscreen"
										whileInView="onscreen"
										viewport={{ once: true, amount: 0 }}
										onClick={() => onOpenAnswear(i)} key={i}
										className={styles.questionsItem}
							>
								<motion.div variants={variantQuestionBlock} className={styles.questionsItemQuestion}>
									<P>{el.question}</P>

									<Div
										className={
											isOpen ? styles.questionsItemQuestionOpen : styles.questionsItemQuestionClose
										}
									>
										{svgSprite("question-page-icon", {
											style: { width: "24px", height: "24px" },
										})}
									</Div>
								</motion.div>
								{isOpen && <Div className={styles.questionsItemAnswear}>{el.answear}</Div>}
							</motion.div>
						);
					})}
				</Div>
			</Div>
		</Div>
	);
};
