import React from "react";
import styles from "./Advantages.module.scss";
import { Div, H2, P, svgSprite } from "../../../../hybrid/wrappers";
import T from "../../../../common/components/T";

export const Advantages = ({ mainStyles }) => {
	return (
		<Div className={styles.wrapper}>
			<Div className="main-page__container">
				<H2 className={styles.title}>
					<T textName="advantagesPageTitle" defaultStr="быть нашим партнером - это" page="/" />
				</H2>
				<Div className={styles.circlesWrapper}>
					<Div>
						<P>
							<T textName="advantagesPageItem-1" defaultStr="известный бренд" page="/" />
						</P>
					</Div>
					<Div>
						<P>
							<T textName="advantagesPageItem-2" defaultStr="качественный продукт" page="/" />
						</P>
					</Div>
					<Div>
						<P>
							<T textName="advantagesPageItem-3" defaultStr="четкая система" page="/" />
						</P>
					</Div>
					<Div>
						<P>
							<T textName="advantagesPageItem-4" defaultStr="лояльные клиенты" page="/" />
						</P>
					</Div>
					<Div className={styles.mainCircle}>
						<P>business</P>
						{svgSprite("advantages-logo", {
							style: mainStyles.advantagesLogo,
						})}
					</Div>
				</Div>
			</Div>
		</Div>
	);
};
