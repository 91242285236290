import React from "react";
import { motion } from 'framer-motion'
import styles from "./Services.module.scss";
import T from "../../../../common/components/T";
import { Div, H2, Img, P } from "../../../../hybrid/wrappers";


const titleVariants = {
	offscreen: {
		x: 200,
		opacity: 0
	},
	onscreen: {
		x: 0,
		opacity: 1,
		transition: {
			duration: 0.4
		}
	}
};

const blockVariantsRight = {
	offscreen: {
		opacity: 0,
		x: 200,
	},
	onscreen: custom => ( {
		opacity: 1,
		x: 0,
		transition: {
			duration: 0.6,
			delay: custom * 0.3
		}
	})
};
const blockVariantsLeft = {
	offscreen: {
		opacity: 0,
		x: -400,
	},
	onscreen: custom => ( {
		opacity: 1,
		x: 0,
		transition: {
			duration: 0.4,
			delay: custom * 0.3
		}
	})
};
export const Services = () => {
	const ServisesData = [
		{
			img: "./src/img/main/services/product-1.webp",
			text: (
				<P>
					<span>
						<T textName="servicesPageItem-1Span" defaultStr="До 20% кэшбека " page="/" />
					</span>
					<T textName="servicesPageItem-1" defaultStr="от каждой продажи" page="/" />
				</P>
			),
			motionType: blockVariantsLeft,
			motionId : 1,
		},
		{
			img: "./src/img/main/services/product-2.webp",
			text: (
				<P>
					<span>
						<T textName="servicesPageItem-2Span" defaultStr="Офисы во всех городах " page="/" />
					</span>
					<T textName="servicesPageItem-2" defaultStr="Казахстана" page="/" />
				</P>
			),
			motionType: blockVariantsRight,
			motionId : 1,
		},
		{
			img: "./src/img/main/services/product-3.webp",
			text: (
				<P>
					<span>
						<T textName="servicesPageItem-3Span" defaultStr="Система тренингов для обучения" page="/"/>
					</span>
					<T textName="servicesPageItem-3" defaultStr=" по продуктам и по ведению бизнеса" page="/" />
				</P>
			),
			motionType: blockVariantsLeft,
			motionId : 2,
		},
		{
			img: "./src/img/main/services/product-4.webp",
			text: (
				<P>
					<T textName="servicesPageItem-4" defaultStr="Бесперебойная " page="/" />
					<span>
						<T textName="servicesPageItem-4Span" defaultStr="поставка товара" page="/" />
					</span>
				</P>
			),
			motionType: blockVariantsRight,
			motionId : 2,
		},
		{
			img: "./src/img/main/services/product-5.webp",
			text: (
				<P>
					<span>
						<T textName="servicesPageItem-5Span" defaultStr="Вознаграждения и бонусы " page="/" />
					</span>
					<T textName="servicesPageItem-5" defaultStr="за выполнение плана продаж" page="/" />
				</P>
			),
			motionType: blockVariantsLeft,
			motionId : 3,
		},
		{
			img: "./src/img/main/services/product-6.webp",
			text: (
				<P>
					<T textName="servicesPageItem-6" defaultStr="Налаженная  " page="/" />
					<br />
					<span>
						<T textName="servicesPageItem-6Span" defaultStr="логистика" page="/" />
					</span>
				</P>
			),
			motionType: blockVariantsRight,
			motionId : 3,
		},
	];

	return (
		<motion.div className={styles.wrapper}
					initial="offscreen"
					whileInView="onscreen"
					viewport={{ once: true, amount: 0.2 }}
		>
			<Div className="main-page__container">
				<motion.h2 variants={titleVariants} className={styles.title}>
					<T textName="servicesPageTitle" defaultStr="Что мы предоставляем партнерам" page="/" />
				</motion.h2>
				<Div className={styles.servicesWrapper}>
					{ServisesData.map((el, i) => {
						return (
							<motion.div variants={el.motionType} custom={el.motionId} key={i} className={styles.servicesItem}>
								<Img className={styles.servicesItemImage} src={el.img} alt={`services-${i}`} />
								<Div className={styles.servicesItemContent}>{el.text}</Div>
							</motion.div>
						);
					})}
				</Div>
			</Div>
		</motion.div>
	);
};
